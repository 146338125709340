import React, { Component } from "react"
import Header from "./header"
import Footer from "./footer"
import {Helmet} from 'react-helmet'

import "./layout.css"
class layout extends Component {
  state = {
    smallScreen: false,
  }

  componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this))
    this.resize()
  }

  resize() {
    this.setState({ smallScreen: window.innerWidth <= 975 })
  }
  render() {
    const { children, page } = this.props
    return (
      <div className={`main ${this.state.smallScreen && "small"}`}>
        <Helmet>
            <title>Bark &amp; Breakfast</title>
            <link rel="preconnect" href="https://fonts.gstatic.com" />
            <link href="https://fonts.googleapis.com/css2?family=Cherry+Swash&display=swap" rel="stylesheet"></link>
        </Helmet>
        <Header page={page} smallScreen={this.state.smallScreen} />
        <div className="content">{children}</div>
        <Footer />
      </div>
    )
  }
}

export default layout
