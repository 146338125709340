import React, { Component } from "react"

class Footer extends Component {
  render() {
    return (
      <footer className="footer"> 
      Contact Us: <a href="tel:(734)272-1270">(734)272-1270</a> &middot; <a href="mailto:gussiewachtmann@gmail.com">gussiewachtmann@gmail.com</a>
      </footer>
    )
  }
}
export default Footer
